window.$ = require("jquery");
window.jQuery = window.$;

$(function () {
	var sliderautoplay = setInterval(function() {
		$(".intervju").each(function() {
			var sliderId = $(this).data('sliderId');
			autoPlay(sliderId);
		});
	}, 6000);

	$(".js-intervju_slide-next").on("click", function () {
		var sliderId = $(this).data('sliderId');
		nextSlide(sliderId);
		clearInterval(sliderautoplay);
	});

	$(".js-intervju_slide-prev").on("click", function () {
		var sliderId = $(this).data('sliderId');
		prevSlide(sliderId);
		clearInterval(sliderautoplay);
	});

	function autoPlay(sliderId) {
		nextSlide(sliderId);
	}

	function nextSlide(sliderId) {
		var count = $(".js-current_intervju_slide[data-slider-id='" + sliderId + "']").data('count') || 1;
		var currentSlideIndex = $(".intervju[data-slider-id='" + sliderId + "'] .intervjuer.visible").index();
		var amountSlides = $(".intervju[data-slider-id='" + sliderId + "'] .intervjuer").length - 1;

		if (currentSlideIndex >= amountSlides) {
			$(".intervju[data-slider-id='" + sliderId + "'] .intervjuer").eq(currentSlideIndex).removeClass("visible");
			$(".intervju[data-slider-id='" + sliderId + "'] .intervjuer").eq(0).addClass("visible");
		} else {
			$(".intervju[data-slider-id='" + sliderId + "'] .intervjuer").eq(currentSlideIndex).removeClass("visible");
			$(".intervju[data-slider-id='" + sliderId + "'] .intervjuer").eq(currentSlideIndex + 1).addClass("visible");
		}

		count++;

		if (count > amountSlides + 1) {
			count = 1;
		}

		$(".js-current_intervju_slide[data-slider-id='" + sliderId + "']").data('count', count).html(count);
	}

	function prevSlide(sliderId) {
		var count = $(".js-current_intervju_slide[data-slider-id='" + sliderId + "']").data('count') || 1;
		var currentSlideIndex = $(".intervju[data-slider-id='" + sliderId + "'] .intervjuer.visible").index();
		var amountSlides = $(".intervju[data-slider-id='" + sliderId + "'] .intervjuer").length;

		$(".intervju[data-slider-id='" + sliderId + "'] .intervjuer").eq(currentSlideIndex).removeClass("visible");
		$(".intervju[data-slider-id='" + sliderId + "'] .intervjuer").eq(currentSlideIndex - 1).addClass("visible");

		count--;

		if (count == 0) {
			count = amountSlides;
		}

		$(".js-current_intervju_slide[data-slider-id='" + sliderId + "']").data('count', count).html(count);
	}

	$(".intervju").each(function() {
		var sliderId = $(this).data('sliderId');
		var slidesAmount = $(this).find('.intervju-slider').length;


		$(".js-intervju_slidesamount[data-slider-id='" + sliderId + "']").html(slidesAmount);
	});
});
